import { registerComponent } from './hookManager'

/**
 * Asynchronously loads and registers the active plugins.
 *
 * @param {string[]} activePlugins - An array of the names of the active plugins to be loaded.
 * @param {string} pluginsPath - The path to the plugins folder (e.g. "/src/plugins")
 * @returns {Promise<void>}
 */
export async function loadPlugins(activePlugins, pluginsPath = '/src/plugins') {
  // console.log('loadPlugins:', activePlugins)

  await Promise.all(
    activePlugins.map(async (pluginName) => {
      try {
        const moduleImport = await import(
          /* @vite-ignore */
          `${pluginsPath}/${pluginName}/index.ts`
        )
        moduleImport.default.definePluginComponents(registerComponent)
      } catch (error) {
        // console.error(`Failed to load plugin ${pluginName}:`, error)
      }
    })
  )
}
