import { RootState } from '@/store'
import { ActionContext } from 'vuex'

export enum DrawerActions {
  OPEN = 'open',
  CLOSE = 'close',
  OPEN_NEXT = 'openNext',
  RESET_STATE = 'reset-state',
}

export enum DrawerMutations {
  SET_CURRENT = 'setCurrent',
  SET_NEXT = 'setNext',
  RESET_STATE = 'clear-state',
}

export enum DrawerGetters {
  GET_CURRENT_DRAWER = 'get-current-drawer',
  GET_SEARCH_STRING = 'get-search-string',
}

export interface DrawerState {
  current?: Drawer
  next?: Drawer
}

export interface Drawer {
  component: string | null
  title: string | null
  searchString?: string | undefined
  version?: string
}

type DrawerActionContext = ActionContext<DrawerState, RootState>

const initState = (): DrawerState => ({
  current: undefined,
  next: undefined,
})

export default {
  namespaced: false,
  state: initState,
  mutations: {
    [DrawerMutations.SET_CURRENT](state: DrawerState, drawer: Drawer): void {
      state.current = drawer
    },
    [DrawerMutations.SET_NEXT](state: DrawerState, drawer: Drawer): void {
      state.next = drawer
    },
    [DrawerMutations.RESET_STATE](state: DrawerState) {
      Object.assign(state, initState())
    },
  },
  actions: {
    [DrawerActions.RESET_STATE]({ commit }: DrawerActionContext): void {
      commit(DrawerMutations.RESET_STATE)
    },
    [DrawerActions.OPEN](
      { dispatch, commit, state }: DrawerActionContext,
      drawer: Drawer
    ): void {
      if (state.current) {
        commit(DrawerMutations.SET_NEXT, drawer)
        dispatch('close')
        return
      }
      commit(DrawerMutations.SET_CURRENT, drawer)
    },
    [DrawerActions.CLOSE]({ commit }: DrawerActionContext): void {
      commit(DrawerMutations.SET_CURRENT, null)
    },
    [DrawerActions.OPEN_NEXT]({ commit, state }: DrawerActionContext): void {
      if (state.next) {
        commit(DrawerMutations.SET_CURRENT, state.next)
        commit(DrawerMutations.SET_NEXT, null)
      }
    },
  },
  getters: {
    [DrawerGetters.GET_CURRENT_DRAWER]: (
      state: DrawerState
    ): Drawer | undefined => state.current,
    [DrawerGetters.GET_SEARCH_STRING]: (
      state: DrawerState
    ): string | undefined => state.current?.searchString?.trim(),
  },
}
