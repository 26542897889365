import { reactive, computed, defineAsyncComponent, markRaw } from 'vue'

/**
 * A reactive object that stores the registered components for each hook.
 * This object is used to keep track of the components that have been registered
 * with the `registerComponent` function.
 */
const hookComponents = reactive({})

/**
 * Registers a component to be used with a specific hook.
 *
 * This function adds a new component entry to the `hookComponents` object,
 * which is a reactive object that stores the registered components for each hook.
 * The component entry includes the component loader, props, order, and an optional component ID.
 *
 * @param {string} hookName - The name of the hook to register the component for.
 * @param {any} componentLoader - The component loader function or object.
 * @param {object} [props={}] - The default props for the component.
 * @param {number|null} [order=null] - The order in which the component should be rendered, or `null` if the order doesn't matter.
 * @param {string|null} [componentId=null] - An optional unique identifier for the component.
 */
export function registerComponent(
  hookName,
  componentLoader,
  props = {},
  order = null,
  componentId = null
) {
  // console.log(`Registering component for hook: ${hookName}`)
  if (!hookComponents[hookName]) {
    hookComponents[hookName] = []
  }

  const asyncComponent = markRaw(defineAsyncComponent(componentLoader))

  const componentEntry = {
    componentLoader: asyncComponent,
    props,
    order,
    componentId,
  }

  hookComponents[hookName].push(componentEntry)

  // console.log(`hook "${hookName}":`, componentEntry)
}

/**
 * Returns the reactive object that contains the registered components for all hooks.
 *
 * This function provides access to the internal `hookComponents` object,
 * which stores the registered components for each hook.
 * It can be used to inspect or manipulate the registered components.
 *
 * @returns {import('vue').Ref<Record<string, { componentLoader: any; props: object; order: number | null; componentId: string | null }[]>>}
 * - The reactive object containing the registered components for all hooks.
 */
export function useHookComponents() {
  return hookComponents
}

/**
 * Sorts an array of component entries by their `order` property.
 *
 * If both `a.order` and `b.order` are not null, the function will sort the components
 * based on the difference between their `order` values. If only one of the `order`
 * properties is not null, the component with the non-null `order` will be sorted
 * before the one with a null `order`. If both `order` properties are null, the
 * function will return 0, indicating no sort order preference.
 *
 * @param {Array<{ order: number | null }>} components - The array of component entries to sort.
 * @returns {Array<{ order: number | null }>} - The sorted array of component entries.
 */
function sortComponentsByOrder(components) {
  return components.sort((a, b) => {
    if (a.order !== null && b.order !== null) {
      return a.order - b.order
    } else if (a.order !== null) {
      return -1
    } else if (b.order !== null) {
      return 1
    }
    return 0
  })
}

/**
 * Returns a computed reactive object that contains the registered components for the specified hook.
 *
 * This function provides access to the internal `hookComponents` object,
 * which stores the registered components for each hook.
 * The returned object is sorted by the `order` property of each component entry.
 *
 * @param {string} hookName - The name of the hook to retrieve the registered components for.
 * @returns {import('vue').Ref<{ componentLoader: any; props: object; order: number | null; componentId: string | null }[]>}
 * - A computed reactive object containing the registered components for the specified hook, sorted by order.
 */
export function getHookComponents(hookName) {
  return computed(() => {
    const components = hookComponents[hookName] || []
    return sortComponentsByOrder(components.slice())
  })
}

/**
 * Sets the props for all components registered for a given hook.
 *
 * @param {string} hookName - The name of the hook to update the component props for.
 * @param {object} props - The new props to set for all components registered for the hook.
 */
export function setHookProps(hookName, props) {
  const components = hookComponents[hookName]
  if (!components) {
    // console.warn(`Hook ${hookName} non trovato.`)
    return
  }
  components.forEach((componentEntry) => {
    componentEntry.mainProps = props
  })
}
